var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "UsersIcon",
      "size": "30"
    }
  }), _vm._v(" Pacientes Cadastrados ")], 1), _c('h4', {
    staticClass: "mt-1"
  }, [_vm._v("Selecione um paciente:")])])], 1)], 1)], 1)], 1), _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.fetchUsers.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Buscar Paciente"
    },
    model: {
      value: _vm.filtro.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "firstName", $$v);
      },
      expression: "filtro.firstName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  })], 1)], 1), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "refUserListTable",
    staticClass: "position-relative",
    staticStyle: {
      "min-height": "50vw"
    },
    attrs: {
      "items": _vm.fetchedUsers,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(nome)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.physicalPerson.firstName) + " " + _vm._s(data.item.physicalPerson.lastName) + " ")];
      }
    }, {
      key: "cell(cadastro)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.createdAt) + " ")];
      }
    }, {
      key: "cell(localizacao)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.physicalPerson.person.city) + " - " + _vm._s(data.item.physicalPerson.person.state) + " ")];
      }
    }, {
      key: "cell(user)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "32",
                  "src": data.item.avatar,
                  "text": _vm.avatarText(data.item.fullName),
                  "variant": "light-".concat(_vm.resolveUserRoleVariant(data.item.role)),
                  "to": {
                    name: 'apps-users-view',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap",
          attrs: {
            "to": {
              name: 'apps-users-view',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.fullName) + " ")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("@" + _vm._s(data.item.username))])], 1)];
      }
    }, {
      key: "cell(role)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          class: "text-".concat(_vm.resolveUserRoleVariant(data.item.role)),
          attrs: {
            "icon": _vm.resolveUserRoleIcon(data.item.role),
            "size": "18"
          }
        }), _c('span', {
          staticClass: "align-text-top text-capitalize"
        }, [_vm._v(_vm._s(data.item.role))])], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          staticClass: "text-capitalize",
          attrs: {
            "pill": "",
            "variant": "light-".concat(_vm.resolveUserStatusVariant(data.item.physicalPerson.person.status))
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveUserStatusText(data.item.physicalPerson.person.status)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', [_c('feather-icon', {
          attrs: {
            "icon": "PlayIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50",
          attrs: {
            "onclick": "location.href='/consulta'"
          }
        }, [_vm._v("Iniciar para Consulta")])], 1), _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'pacienteDetail',
              params: {
                pacienteId: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FolderIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Prontuário")])], 1), _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'resultado'
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Resultados")])], 1), _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'pacienteEdit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Editar Paciente")])], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }